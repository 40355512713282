(function () {
    'use strict';

    agGrid.initialiseAgGridWithAngular1(angular);

    var dependencies = [
        //routes
        "common.routes",
        "common.baseurl",
        "common.appversion",
        "ngRoute",
        "ui.router",

        //bootstrap
        "ui.bootstrap",
        "ui.bootstrap.tpls",
        "ui.bootstrap.modal",
        "ui.bootstrap.buttons",
        "ui.bootstrap.accordion",
        "ui.bootstrap.popover",
        "ui.bootstrap.tabs",
        "ui.bootstrap.datetimepicker",

        "agGrid",
        "ngAnimate",
        "ngFileUpload",
        "ngResource",
        "ngSanitize",
        "pascalprecht.translate",
        "dibari.angular-ellipsis",
        "countrySelect",
        "ui-notification",
        "ui.dateTimeInput",
        "angularMoment",
        "angularSpinner",
        "angular.filter",
        "rt.encodeuri",
        "rolesConfig",
        "systemMessages",
        "licenseFilter",
        "3wks.selectAll",
        "3wks.stopPropagation",
        "ctp.scrollThenFix",
        "ctp.zeroHeight",
        "ejangular",
        "blockUI"
    ];

    angular.module('common', dependencies)
        .run(['$rootScope', function ($rootScope) {
            $rootScope._ = _;
        }])
        .run(['$rootScope', '$location', '$interval', '$state', 'CommonService', 'SessionService', 'routesConstant',
            function ($rootScope, $location, $interval, $state, CommonService, SessionService, routesConstant) {

            CommonService.getAppInfo()
                .then(function(response) {
                    $rootScope.appInfo = response;
                }, function(error) {
                    // if error, let's try again
                    CommonService.getAppInfo()
                        .then(function(response) {
                            $rootScope.appInfo = response;
                        });
                });

            $interval(function () {
                if ($state.includes('login') || $state.includes('logout')) {
                    return;
                }
                if (SessionService.isExpired()) {
                    $state.go(routesConstant.LOGIN.LOGOUT.stateName);
                }
            }, 10000); // 10 second interval
        }]);

})();

